/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1288px) {

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}

.btn_round {
	min-width: 180px; font-size: 12px;
	&.little {line-height: 40px;}
}

.wrapper {
    width: 980px;
    &.w126 {
    	width: 940px;
    }
    &.det_des {width: 960px;}
}

/* header
----------------------------------------------------------------------------------------------*/

header {
	.logo {width: 340px; position: fixed; left: 0; right: 0; margin: auto !important;}
	.lf_head {
		margin-top: 10px;
		.burger .ico_brger:hover span {background: #333;}
	}
	.rh_head {
		nav {
			margin-top: 0;
			a {
				display: none;
				&.btn_round {display: inline-block;}
			}
		}
	}
	&.home {
		.lf_head {
			.burger .ico_brger:hover span {background: #fff;}
		}
	}
	&.float {
		.logo {width: 340px;}
		.lf_head {
			.burger .ico_brger:hover span {background: #333;}
		}
	}
}


.mnu_wrap {
	nav {
		width: 40%;
		ul {
			li {
				a {font-size: 45px;}
				.sub {
					left: 100%; padding-top: 15px; padding-left: 230px;
					.line {top: 30px;}
					ul {
						li {
							a {font-size: 22px;}
						}
					}
				}
			}
		}
	}
}

/* homepage
----------------------------------------------------------------------------------------------*/
.slideHome {
	.slider {
		.slide {
			.capt_slide {
				top: 36%; @include transform(translateY(0%));
				h6 {font-size: 18px; width: 700px;}
				h1 {font-size: 50px; line-height: 60px; width: 700px;}
			}
		}
		.slick-dots {
			width: auto; left: 40px; right: 40px;
		}
	}
	&.contentslider{ 
        width: auto; margin: 0 30px; margin-bottom: 70px;
        .slider{
            .slick-dots{ width: auto; left: 40px; right: 40px; }
        }
    }
}
.bannerslide{
    .slideHome.contentslider{ 
        width: auto; margin: 0 30px; margin-bottom: 70px;
        .slider{
            .slick-dots{ width: auto; left: 40px; right: 40px; }
        }
    }
}
.pack_detail{
    .wrapper.w126 { width: 950px; }
}
.sec_journey {
	.select_journey {
		.sel {
			select {width: 200px; padding: 16px 30px;}
		}
	}
	.btn_round {margin-left: 20px; min-width: 160px;}
}

.txt_top {
	h6 {font-size: 13px;}
	h1 {font-size: 40px;}
	p {font-size: 16px; line-height: 26px;}
}

.home_about {
	padding: 75px 0 60px;
}
.why_list {
	width: auto;
	.box {
		width: 290px; margin-right: 55px;
		.dec {
			h3 {font-size: 16px; line-height: 20px; margin-bottom: 20px;}
		}
	}
}

.home_service {
	padding: 65px 0;
	.content_service {width: 880px; margin: 0 auto;}
	.nav_servive {
		.list {
			a {font-size: 15px;}
		}
	}
}
.slider_service {
	.slide {
		width: 282px; min-height: 350px;
		.ket {
			p {font-size: 15px;}
		}
	}
	.slick-arrow {
		&.slick-prev {left: -65px;}
		&.slick-next {right: -65px;}
	}
}
.list_desti {
	.slide {
		width: 310px; margin-right: 25px; min-height: 350px;
		&:nth-of-type(4n) {margin-right: 25px;}
		&:nth-of-type(3n) {margin-right: 0;}
		.ket {
			p {font-size: 15px;}
		}

	}
}
.recomend_list {
	margin-bottom: 70px; min-height: 380px;
	.item {
		width: 310px; margin-right: 25px;
		&:nth-of-type(4n) {margin-right: 25px;}
		&:nth-of-type(3n) {margin-right: 0;}
		.dec {
			padding: 20px;
			h3 {font-size: 14px;}
			p {font-size: 14px;}
			.price_bx {
				margin: 10px -20px -20px;
				label {font-size: 11px;}
				.abs {font-size: 11px;}
				h6 {font-size: 16px;}
			}
		}
	}
}
.sec_recomend {
	.recomend_list {
		.item {
			&:nth-of-type(4) {display: none;}
		}
	}
}

.home_travel {
	height: 300px; overflow: hidden;
	.text_travel {
		width: 690px;
		h3 {font-size: 24px; line-height: 36px; }
	}
	> img {position: relative; height: 100%; width: 100%; object-fit: cover;}
}
.home_testimonial {
	padding-top: 20px; padding-bottom: 60px;
	.slider_testimo {
		width: 720px;
		.slide {
			h3 {font-size: 18px; line-height: 26px; margin-bottom: 15px;}
			p {font-size: 14px; line-height: 24px;}
			.info {
				.right {
					div {font-size: 14px;}
				}
			}
		}
		.slick-arrow {
			&.slick-next,
			&.slick-prev {
				width: 30px; background-position: left top;height: 10px;
				&:hover {
					&:after {width: 30px;background-position: left bottom;}
				}
			}
			&.slick-next {right: -60px;}
			&.slick-prev {left: -60px;}
		}
	}
}

/* middle
----------------------------------------------------------------------------------------------*/
.middle {
	padding: 80px 0 50px;
}
.banner {
	margin-bottom: 70px;
}
.banner_top {
	.capt {
		h6 {font-size: 18px;}
		h1 {font-size: 50px; line-height: 60px;}
	}
}
.breadcrumb {
	height: 50px; padding: 15px 25px;
}
.text_inner {
	.lf_txt {
		width: 300px;
		h3 {font-size: 22px; line-height: 32px;}
		p {font-size: 16px; line-height: 26px;}
	}
	.rh_txt {
		width: 620px;
		p {font-size: 16px; line-height: 26px;}
		ul,ol {
			li {font-size: 16px; line-height: 26px;}
		}
	}
	&.btm_bnnr {
		padding-bottom: 75px;
	}
}

.sec_service {
	.wrapper {width: 980px;}
	.blok_service {
		.img_lf {width: 500px;}
		.right_dec {width: 380px; padding: 0;}
	}
	.slider_partner {
		width: 810px;
		.partner {margin: 0 10px;}
	}
}
.career_wrap {width: auto;}
.list_career {
	.item {
		padding: 0 30px 20px;
		.jobs {
			.status {font-size: 13px;}
			h3 {font-size: 22px;}
		}
		.learn {font-size: 12px;}
	}
}
.pagging {
	a {font-size: 14px;}
}

.form_box {
	.row {
		.field {
			input[type="text"], 
			input[type="email"], 
			input[type="password"], 
			input[type="number"], select,textarea {padding: 12px 20px;}

			.qty_input {
				.input_val {font-size: 18px;}
			}
		}
		&.sum {
			.field {
				&.full {
					.block {
						h6 {padding-right: 12%;}
					}
				}
			}
		}
	}
	.input_file {
		height: 44px;
		input {height: 44px;}
	}
	&.form_contact {
		.row {
			label {font-size: 16px;}
			.field {
				input[type="text"], 
				input[type="email"], 
				input[type="password"], 
				input[type="number"], select,textarea {padding: 12px 20px; font-size: 16px;}
			}
		}
	}
	.select2-container {width: 100% !important;}
	.select2-container--default .select2-selection--multiple .select2-selection__rendered {padding: 10px;}
}

.nav_destination {
	.list {
		a {font-size: 15px;}
	}
}

.detail_sec {
	> h3 {font-size: 24px;}
	.text_article {
		.isi {max-height: 104px;}
		p {font-size: 16px; line-height: 26px;}
		.rd_more {font-size: 12px;}
	}
	&.text_tmt {
		background-position: top center;
		.dec_txt {
			padding-top: 60px;
			h2 {font-size: 24px;}
			p {font-size: 16px; line-height: 26px;}
		}
	}
}
.item_detail {
	.box {
		width: 23.4375%; margin-right: 2.08333%;
		&:nth-of-type(5) {display: none;}
		&:nth-of-type(4n) {margin-right: 0;}
		.name {left: 20px; right: 20px;}
	}
}

.map_info {
	.map_content {width: 545px;}
	.info {
		width: 390px;
		.label_info {padding: 20px 30px;}
		.ket_map {padding: 15px 30px;}
	}
}
#map_location {height: 310px;}

.package_sec {
	.left_package {
		padding: 0; width: 550px;
		p {font-size: 16px; line-height: 26px;}
	}
	.right_package {
		.ket_btm {
			a.left {font-size: 16px;}
		}
		.box {
			.isi_info {
				.title_box {
					h3 {font-size: 20px;}
					h6 {font-size: 14px; line-height: 18px;}
				}
			}
			&.grey_bx {
				.txt {padding: 25px 20px 25px 25px;}
			}
		}
	}
}
.tab_packages {
	margin: 0;
	.nav {
		padding: 0; margin: 40px 0;
		a {font-size: 15px; margin-right: 50px;}
	}
	.tab_content {
		padding: 0;
		.dec_tab {
			.list_diagram {
				.info_tour {
					.day {
						font-size: 16px;
						&:before {top: 2px;}
					}
					h6 {font-size: 16px;}
				}
			}
			.drop_txt {max-height: 425px;}
			ul {
				li {font-size: 16px; line-height: 26px; margin-bottom: 15px;}
			}
		}
	}
}

.bnnr_dash {
	.img {
		position: relative; height: 190px;
		img {position: absolute;  left: 50%; top: 50%; @include transform(translate(-50%,-50%)); max-width: 1280px;}
	}
	.info_dash {
		.info_profile {
			h4 {font-size: 24px;}
		}
	}
}

.pg_dashboard {
	.block_lf {width: 380px;}
	.block_rh {
		width: 540px;
		.list_trip {
			.box_trip {
				.text_trip {
					left: 50px;
					h6 {font-size: 12px; margin-bottom: 15px;}
					h3 {font-size: 24px; margin-bottom: 10px;}
					p {font-size: 18px;}
					.btn_round {height: 45px; line-height: 45px;}
				}
			}
		}
	}
	.box {
		.isi_info {
			table {
				tbody {
					td {
						font-size: 14px;
						&:last-child a {font-size: 14px;}
					}
				}
			}
			.list-box {
				width: 330px;
				.toggle {
					a {font-size: 14px; padding-left: 25px; background-size: 15px;}
				}
			}
		}
	}
	.box_add {
		.ico {width: 40px;height: 40px; background-size: 40px !important;}
	}
}

.box_booking {
	.item_booking {
		.text_booking {
			h1 {font-size: 24px;}
			p {font-size: 16px; line-height: 26px;}
			.tgl {font-size: 16px;}
			.ket_detail {
				.col {
					label {font-size: 16px; line-height: 24px;}
					p {font-size: 16px; line-height: 24px;margin-left: 160px;}
					ul {
						margin-left: 160px;
						li {font-size: 16px; line-height: 24px;}
					}
				}
			}
		}
	}
}

.list_step {
	width: 830px;
}
.box_signup {
	.img {
		width: 300px; padding-top: 0; overflow: hidden;
		img {object-position: right center;}
		/*&.sign_img {
			padding-top: 0px;
			img {max-width:510px; left: -30%;}
		}
		&.sign2 {
			padding-top: 0;
			img {max-width: 540px; left: -40%;}
		}
		&.register {
			img {max-width: 500px;}
		}*/
		/*img {position: relative; max-width: none; left: -19%;}*/
	}
	.right_form {width: 650px; padding-right: 50px;}
}
.top_form {
	h2 {font-size: 20px; background-size: 24px; padding-left: 40px;}
}

.signin_pg {padding-top: 80px;}
.form_sign {
	width: 400px;
	h6 {font-size: 13px;}
	h1 {font-size: 40px;}
	input[type="email"], input[type="password"] {
		padding: 15px 25px;
	}
	.link_btn {
		.btn_round.orn {min-width: 130px;}
	}
}
.thanks_pg {
	padding-top: 30px;
	.text_thanks {
		h1 {font-size: 55px;}
		p {font-size: 16px; line-height: 26px;}
	}
}
.list_result {
	.item {
		.dec_result {
			width: 580px;
			h2 {font-size: 24px;}
			p {font-size: 16px; line-height: 26px;}
		}
	}
}


#pop_hotel {
	.pop_inner {
		.content_pop {
			width: 940px;
			.content_resort {
				.left {
					h4 {font-size: 16px;}
					p {font-size: 16px; line-height: 24px;}
					ul {
						li {font-size: 16px; line-height: 24px;}
					}
				}
			}
		}
	}
}


/* footer
----------------------------------------------------------------------------------------------*/

footer {
	.top_foot {
		padding: 60px 0 50px;
		.mnu_foot {
			width: 435px;
			ul {
				li {
					width: 170px;
					a {font-size: 13px;}
				}
			}
		}
	}
}




}